.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;
  animation: myAnim 10s cubic-bezier(0.32, 0, 0.67, 0) 0s 1 normal forwards;

  

  // svg {
  //   width: 100%;
  //   height: auto;
  //   bottom: 0;
  //   transform: rotateZ(30deg) !important;
  // }

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 1;
    transform: rotateZ(30deg) !important;
    z-index: 1;
    
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}

@keyframes myAnim {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}